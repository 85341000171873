import Features from "../../components/Home/Features";
import Footer from "../../components/Footer";
import Downbar from "../../components/down_footer_pulizie";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Card from "../../components/Card";
import { Helmet } from "react-helmet"
import { Link } from "gatsby";
import * as React from "react";
import "../../styles/main.css";
import { pageStyles,cityDiv,mapDiv } from "../../styles/style";
import FloatButton from "../../components/FloatButton";
import { StaticImage } from "gatsby-plugin-image";
import city from "../../images/copertura/Cesano_Boscone.jpg";

// manutenpul styles
// data
// cards services

const services = [
  {
    'image': <StaticImage src="../../images/services/pulizie_uffici.webp" alt="SERVIZI DI PULIZIA" />,
    'title':'SERVIZI DI PULIZIA',
    'text':'Effettuiamo Pulizie Professionali- Pulizie Uffici, Negozi, Palestre, Studi.- Pulizie Condominiali.- Pulizie in Quota fino a 20 mt.- Sanificazione Bagni, Cucine, Mense. - Pulizie Post-Ristrutturazione.- Pulizie Strutture Sanitarie.',
  },{
    'image':<StaticImage src="../../images/services/sanificazione.webp" alt="SANIFICAZIONE" />,
    'title':'SANIFICAZIONE',
    'text':'Eseguiamo servizi di sanificazione per qualsiasi ambiente civile ed industriale, a Milano e provincia (anche contro Coronvirus-COVID 19). Non è sufficiente igienizzare, ma occorre sanificare e, quindi, abbattere le cariche microbiche per ridurre il rischio di insorgenza e diffusione di patologie ed infezioni.',
  },{
    'image':<StaticImage src="../../images/services/disinfestazioni.webp" alt="DISINFESTAZIONI" />,
    'title':'DISINFESTAZIONI',
    'text':'L’esperienza maturata ci permette oggi, di poter risolvere ogni genere di minaccia accompagnandovi nelle varie fasi di intervento fino alla completa estinzione dell’infestazione. Rivolgiamo i nostri servizi di disinfestazione sia ad utenti privati che a strutture pubbliche e tutti gli interventi sono svolti in rispetto del protocollo HACCP e tutti i prodotti sono presidi medico chirurgici e dotati di scheda tecnica e sicurezza.',
  },{
    'image':<StaticImage src="../../images/services/giardinaggio.webp" alt="GIARDINAGGIO" />,
    'title':'GIARDINAGGIO',
    'text':'Un team di giardinieri si occupa di proteggere e preservare le aree verdi di immobili privati e pubblici.',
  },{
    'image':<StaticImage src="../../images/services/furgon-1.webp" alt="SGOMBERI TRASLOCHI" />,
    'title':'SGOMBERI TRASLOCHI',
    'text':'Si eseguono con puntualità e professionalità sgomberi per privati: cantine, solai, depositi, appartamenti e anche per uffici o attività commerciali.',
  },{
    'image':<StaticImage src="../../images/services/servizi-edili.webp" alt="SERVIZI EDILI" />,
    'title':'SERVIZI EDILI',
    'text':"Si effettuano ristrutturazioni edilizie complete per abitazioni residenziali, appartamenti e locali commerciali. Il team dell'impresa si occupa di ogni fase della ristrutturazione.",
  }
];

// markup
const LodiPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
          <meta charSet="utf-8" />
          <title>Impresa di Pulizie, Sanificazioni e Servizi LODI e Provincia | MANUTENPUL</title>
          <meta name="description" content="Impresa di pulizie LODI e Provincia : MANUTENPUL dal 1990. Affidabilità e tempestività contraddistinguno i nostri interventi di pulizia e sanificazione. Preventivo GRATUITO! Impresa di Pulizie LODI e Provincia - Servizi di Pulizie LODI e Provincia - Pulizie Professionali LODI e Provincia"></meta>
          <meta name="keywords" content="impresa di pulizie Lodi, impresa di sanificazione Lodi, pulizia condominio lodi, giardinaggio Lodi"></meta>
          <meta property="og:title" content="Impresa di Pulizie a LODI e Provincia | MANUTENPUL"></meta>
          <meta property="og:description" content="L'area di LODI e Provincia è coperta al 100% dai servizi di pulizia di MANUTENPUL. Pulizia di uffici e imprese, aree produttive, cliniche e case di cura, appartamenti. 40 anni di esperienza nel settore del cleaning: scopri tutti i servizi disponibili e chiedi un preventivo gratuito, senza impegno."></meta>
      </Helmet>
      <Navbar></Navbar>
      <Downbar></Downbar>
      <Banner pageName="Impresa di Pulizie a LODI" prepageName="Copertura" />
      
      <section className="feature-section pt-60">
        <div className="container">
          <div className="row">

              <div className="col-12 col-sm-6 mb-30 " >
                  <p>L'AREA di <strong>LODI e provincia</strong> è coperta al 100% dai servizi di pulizia di MANUTENPUL.</p>
                  <p>MANUTENPUL è in grado di offriti servizi di pulizia professionale nell'area di LODI e Provincia, Piu di 30 anni di esperienza ed un team di Professionisti 24/24, 7/7 al tuo servizio per qualsiasi esegenza.</p>
                  
                  <div className="section-title text-center mt-25 mb-25">
                  <p>Chiama ora e prenota il servizio adatto a te!<strong>&nbsp;<a href="tel:+39.02.31055156">+39.02.31055156</a></strong></p>
                    <br/>
                    <Link to="https://www.manutenpul.it/contact" className="theme-btn">Richiedi Preventivo GRATUITO</Link>
                  </div>
                  <p>Duranti tutti questi anni di attività abbiamo cercato costantemente di migliorarci attraverso corsi di aggiornamento per garantire a chi cerca un’ impresa di pulizie a LODI, la miglior <strong>QUALITA’</strong> al miglior Prezzo.</p>
              </div>
            
              <div className="col-12 col-sm-6 mb-25" style={cityDiv}>
              <br/> 
                <img src={city} width="100%" alt="LODI" />
              </div>
          </div>
              
          <div className="row">
              <div className="col-12 order-2 col-sm-6  order-sm-1" style={mapDiv}>
              <iframe title="MiniMaps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d814218.5537291087!2d9.002210899992981!3d45.45796484849368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47812612b905cad7%3A0x30678022e668380!2sProvincia%20di%20Lodi!5e0!3m2!1sit!2sit!4v1650009753087!5m2!1sit!2sit" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
              
              <div className="col-12 order-1  col-sm-6  mb-25 order-sm-2" >
                <br />
                <p> La nostra Ditta di pulizie professionali è specializzata nei seguenti settori:</p>
                <br/>
                <ul className="dt-sc-fancy-list green serviceMain arrow"> 
                  <li><i className="material-icons">done_outline</i>&nbsp;<Link to="#"><strong>Servizi di pulizie civili&nbsp;e pulizie industriali</strong></Link></li> 
                  <li><i className="material-icons">done_outline</i>&nbsp;<Link to="#"><strong>Sanificazione reparti alimentari e studi medici</strong></Link></li> 
                  <li><i className="material-icons">done_outline</i>&nbsp;<Link to="#"><strong>Derattizzazione e disinfestazione</strong></Link></li> 
                  <li><i className="material-icons">done_outline</i>&nbsp;<Link to="#"><strong>Fornitura materiali igienico/sanitario</strong></Link></li> 
                  <li><i className="material-icons">done_outline</i>&nbsp;<Link to="#"><strong>Trattamenti speciali per pavimentazioni</strong></Link></li>
                  <li><i className="material-icons">done_outline</i>&nbsp;<Link to="#"><strong>Facchinaggio e sgombero</strong></Link>&nbsp;</li> 
                  <li><i className="material-icons">done_outline</i>&nbsp;<Link to="#"><strong>Giardinaggio / Trattamento del verde</strong></Link>&nbsp;</li> 
                </ul>

                <div className="section-title text-center mt-25 mb-25">
                  <p>Chiama ora e prenota il servizio adatto a te!<strong>&nbsp;<a href="tel:+39.02.31055156">+39.02.31055156</a></strong></p>
                    <Link to="https://www.manutenpul.it/contact" className="theme-btn">Richiedi Preventivo GRATUITO</Link>
                  </div>
              </div>

           </div>
        </div>
      </section>
      <Features
      cityName={"LODI e Provincia"}
      text={"Abbiamo copertura a LODI e Provincia.  "}
      />
      <div className="container">
        <div className="row">
        <div className="col-12 order-2 col-sm-6  order-sm-1" >

                <p>  Tra i servizi più richiesti effettuati a LODI e Provincia abbiamo:</p>
                <br/>
                <ul className="dt-sc-fancy-list green serviceMain arrow"> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Pulizia degli Uffici e Aziende</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Pulizia Scale Condominiali</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Pulizia Studi medici e dentistici</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Pulizia di cucine e mense</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Pulizia Appartamenti e Privati</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Pulizia di negozi e show room</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Pulizia supermercati e centri commerciali</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Servizio di sanificazione reparti alimentari</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Servizio di facchinaggio e sgomberi</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Pulizia case di cura e/o di riposo</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Servizi di pulizia di alberghi</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Fornitura di Materiali cure alle persone</strong></Link></li> 
                </ul>
                
        </div>
              
        <div className="col-12 order-1  col-sm-6  mb-25 order-sm-2" >
        <h4> Prova la qualità che fa la differenza! </h4>
         <p> <strong>Vuoi essere certo della nostra qualità?</strong> La qualità dei nostri servizi è testimoniata dalle nostre ottime referenze che potrai trovare nel “ Portfolio clienti”.
          <br/>
          I nostri clienti e la soddisfazione di questi è un punto fondamentale per la nostra ditta di pulizie professionali: attraverso i nostri clienti, noi riusiamo ad accumulare esperienza professionale sul campo, e a espandere la nostra fama ed il nostro buon nome, in tutta l'area di LODI e Provincia.
          Solo grazie al passaparola, messo in atto dalla nostra clientela, siamo riusciti ad allargare la cerchia dei nostri contatti e siamo riusciti ad affermare il buon nome della nostra impresa di pulizie.
          <strong>Oltre 200 clienti ci ha già scelto!</strong>
          <br/>
          Il nostro <strong>personale qualificato</strong>, i nostri <strong>macchinari di ultima generazione </strong>e la nostra <strong>professionalità </strong>è quello che fa apposta per te!
          I prezzi e le tariffe di manodopera della nostra a ditta di pulizie a LODI e Provincia, sono i più convenienti ed allettanti che troverai sul mercato, e possiamo garantirti che a parità di prezzo, non si riuscirai a trovare un’ impresa di pulizie attenta, qualificata e professionale, che possa offrirti un servizio assolutamente impeccabile.
          <strong> Cosa aspetti a richiedere un preventivo gratuito?</strong></p>      

          <div className="section-title text-center mt-25 mb-25">
            <p>Chiama ora e prenota il servizio adatto a te !<strong><a href="tel:+39.02.31055156">+39.02.31055156</a></strong></p>
              <Link to="https://www.manutenpul.it/contact" className="theme-btn">Richiedi Preventivo GRATUITO</Link>
          </div>
        </div>
          
        </div>

      </div>
      <section id="blog" className="blog-section pt-50 pb-50">
       
        <div className="container">
        {/* <div className="row align-items-end"></div> */}
          <div className="row">
            {services.map(service => (
              <Card
              key={service.title}
              image={service.image}
              title={service.title}
              text={service.text} />
            ))}
          </div>
        </div>

        
      </section>

      <Footer></Footer>
    </main>
  )
}

export default LodiPage;