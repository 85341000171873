import * as React from "react";
 
import {fadeInDownStyle,fadeInUpStyle} from "../../styles/style";

const cardStyle = {
  textAlign: "-webkit-center",
  backgroundColor: "#FFF",
} 

const handleImageCity = (image) =>{
  if(image){
    return <div className="row">
              <div className="col-12">
                <img src={image} width="100%" alt="city"/>
              </div>
           </div>
  }
};

const Features = (props) => {
  return (
    <section className="feature-section pt-60">
      <div className="container">
        {handleImageCity(props.image)}
        <div className="row">
          <div className="col-xl-10 col-lg-7 col-md-9 mx-auto">
            <div className="section-title text-center mb-55">
              <span
                className="wow fadeInDown"
                data-wow-delay=".2s"
                style={fadeInDownStyle}
              >
                IMPRESA DI PULIZIE E SERVIZI A {props.cityName?props.cityName:'MILANO'}
              </span>
              <h2
                className="wow fadeInUp"
                data-wow-delay=".4s"
                style={fadeInUpStyle}
              >
                Perché scegliere noi?
              </h2>
              <p
                className="wow fadeInUp"
                data-wow-delay=".6s"
                style={fadeInUpStyle}
              >
                {props.text}
                L' impresa di pulizie MANUTENPUL srls, opera a Milano dal 1990, ed è impegnata nel campo della sanificazione, 
                disinfestazione e in quello della pulizia di ogni ambiente di lavoro e di vita quotidiana delle persone (case, 
                uffici). Il know-how acquisito in tanti anni di esperienza ci permette di distinguere il nostro servizio per Efficienza, 
                Affidabilità e Tempestività. L’ Efficienza dei servizi offerti dall' impresa di pulizie MANUTENPUL è riscontrabile dalla 
                qualità in rapporto ai costi.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="feature-box box-style" style={cardStyle}>
              <div className="feature-icon box-icon-style aling-center">
                <span className="material-icons">engineering</span>
              </div>
              <div className="box-content-style feature-content">
                <h3>PRODOTTI PROFESSIONALI</h3>
                <p>
                Utilizziamo prodotti professionali Eco Sostenibili.

                Forniamo accessori per ambienti e bagni: cestini, dispenser, 
                carta mani, carta igienica, saponi, profumi per ambienti, bobine carta.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="feature-box box-style" style={cardStyle}>
              <div className="feature-icon box-icon-style">
                <span className="material-icons">construction</span>
              </div>
              <div className="box-content-style feature-content">
                <h4>MACCHINARI INDUSTRIALI</h4>
                <p>
                Macchine Lavasciuga e Macchinari a Spruzzo Estrazione per la pulizia di 
                pavimenti, moquette e tappeti.

                Macchina ad Osmosi Inversa per la pulizia di vetri e vetrate.
                Utilizziamo PLE (piattaforma elevabile) per le pulizie in quota fino a 20 mt..
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="feature-box box-style" style={cardStyle}>
              <div className="feature-icon box-icon-style">
                <span className="material-icons">connect_without_contact</span>
              </div>
              <div className="box-content-style feature-content">
                <h4>SERVIZIO PERSONALIZZATO</h4>
                <p>
                Interventi sia spot che continuativi. Siamo operativi entro 1h dalla richiesta
                 lavorativa. Servizio disponibile 24h/24.

                Siamo operativi 365 giorni all’anno, anche durante le festività.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
