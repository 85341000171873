import * as React from "react";
import {fadeInUpStyle} from "../styles/style";


const Card = (props) => {
    return (
        <div className="col-xl-4 col-lg-4 col-md-6">
            <div className="single-blog mb-40 wow fadeInUp" data-wow-delay=".2s" style={fadeInUpStyle}>
                <div className="blog-img">
                    {props.image}
                </div>
                <div className="blog-content">
                    <h4>{props.title}</h4>
                    <p>{props.text}</p>
                </div>
            </div>
        </div>
)};

export default Card